import s from "./dobroWebAR.module.scss";
import SvgSelector from "shared/components/SvgSelector";
import { useMediaQuery } from "@mui/material";
import { breakpoints } from "styles/variables";

export const DobroWebAR = () => {
  const isMobile = useMediaQuery(breakpoints.mobile);

  return (
    <section className={s.wr}>
      <div className={s.sq1}>
        {isMobile && (
          <img src={require("assets/dobroar/bg-mobile.png")} alt="Stars" />
        )}
        <h1 className={s.newyear}>Запусти добро</h1>
        <h2 className={s.title}>
          Что ассоциируется у вас <br /> с новогодними праздниками?
        </h2>
        <p className={s.text}>
          Уютные домашние посиделки, повсюду огоньки и елочный аромат, прогулки
          под падающими снежинками и, конечно, катание на коньках. Все это
          перенес на свою работу художник Роман Добро. А чтобы работа ожила и
          еще больше напоминала круговорот праздничных дней, оживите ее с
          помощью камеры
        </p>
        <Button />
      </div>

      <div className={s.sq2}>
        <div className={s.text}>
          <h2 className={s.title}>Брусницын</h2>
          <p className={s.text}>
            Культурный квартал Брусницын является центром притяжения уличных
            художников. Здесь вы можете встретить работы таких авторов как
            Базелевс, Nadya O, Miша Mack, Сергей RUSICH и других. Поэтому
            неудивительно, что для оформления катка обратились к команде
            PublicARt выступающей связующим звеном между художниками и бизнесом.
            Они пригласили своего друга, известного петербургского художника
            Романа Добро.
          </p>
        </div>

        <div className={s.image}>
          <img src={require("assets/dobroar/star.png")} alt="Stars" />
        </div>

        <div className={s.text}>
          <h2 className={s.title}>Роман Добро</h2>
          <p className={s.text}>
            В своем творчестве уличный художник Роман Добро создает динамичные,
            цветные, яркие изображения, а также исследует взаимодействие
            классических уличных приемов и экспрессивного текстурирования
            поверхностей. В его работах вы найдете художественные размышления
            на такие важные темы как мир, любовь, добро и искренность. Не
            случайно работа для Брусницына получилась такой позитивной
            и светлой.
          </p>
        </div>

        <div className={s.image}>
          <img src={require("assets/dobroar/star.png")} alt="Stars" />
        </div>

        <div className={s.text}>
          <h2 className={s.title}>LEGENDA</h2>
          <p className={s.text}>
            На рынке девелопмента LEGENDA устойчиво держит репутацию
            прогрессивного и творческого игрока, создающего и поддерживающего
            проекты в области дизайна и современных технологий. От реализации
            собственных дизайнерских зон в знаковых городских пространствах
            до партнерской поддержки частных инициатив, как в случае
            с арт-объектом на катке.
          </p>
        </div>

        <div className={s.partners}>
          <img src={require("assets/dobroar/partner2.png")} alt="Stars" />
          <img src={require("assets/dobroar/partner1.png")} alt="Stars" />
        </div>
      </div>
      <button className={s.btn2} onClick={() => window.scrollTo(0, 0)}>
        <p>↑ Вернуться вверх</p>
      </button>
    </section>
  );
};

const Button = () => {
  // const [text, setText] = useState("Запустить AR");
  return (
    <a
      className={s.btn}
      href="https://lenor.8thwall.app/legenda/"
      target="_blank"
      rel="noopener noreferrer"

      // onMouseDown={() => setText("Запущено")}
      // onMouseOver={() => setText("Запустить AR")}
    >
      <SvgSelector svg={"ar-button"} />
      <span>Запустить AR</span>
    </a>
  );
};

import { useTranslation } from "react-i18next";
import s from "./Footer.module.scss";
import SvgSelector from "shared/components/SvgSelector";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";

const socialLinks = [
  {
    url: "https://vk.com/pushkeenru",
    svg: "vk",
  },
  {
    url: "https://t.me/pushkeen_ru",
    svg: "telegram",
  },
];

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className={s.footer} id="footer">
      <div className="container">
        <div className={s.wr}>
          <a className={s.email} href="mailto:hey@pushkeen.ru">
            hey@pushkeen.ru
          </a>
          <a className={s.phone} href="tel:+78126794847">
            + 7 (812) 679-48-47
          </a>
          <a
            className={s.address}
            href="https://yandex.ru/maps/-/CCUNE-uU2B"
            target="_blank"
            rel="nofollow"
          >
            <ReactMarkdown>{t("footer.address")}</ReactMarkdown>
          </a>
          <ul className={s.links}>
            {socialLinks.map((link, i) => (
              <a key={i} href={link.url} target="_blank" rel="nofollow">
                <SvgSelector svg={link.svg} />
              </a>
            ))}
          </ul>
          <span className={s.label}>
            <span>PushKeen © 2021-{new Date().getFullYear()}</span>
            <span>ООО «Цифровые решения»</span>
            <span>ОГРН 1207800094127</span>
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import styled from "styled-components";

const steps = [
  "Информация о компании",
  "Деятельность заказчика",
  "подробнее о проекте",
  "дизайн",
  "функциональность сайта",
];

export const HorizontalStepper = ({ activeStep }) => {
  return (
    <Box sx={{ width: "90%", margin: "0 auto" }}>
      <Stepper alternativeLabel activeStep={activeStep}>
        {steps.map((label) => (
          <StepSt key={label}>
            <StepLabelSt>{label}</StepLabelSt>
          </StepSt>
        ))}
      </Stepper>
    </Box>
  );
};

const StepSt = styled(Step)`
  & .MuiStepConnector-root {
    z-index: -1;
    top: 44px;
  }
`;
const StepLabelSt = styled(StepLabel)`
  flex-direction: column;
  gap: 10px;
  font-family: "Mulish", sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 200%;
  text-transform: uppercase;
  text-align: center;
  color: #181818;
  max-width: 212px;

  & .MuiStepLabel-iconContainer {
    padding: 0;
    width: 88px;
    height: 88px;
    & .MuiSvgIcon-root {
      width: 100%;
      height: 100%;
      color: #dadada;
      & text {
        fill: #999;
        font-family: "IBM Plex Mono", sans-serif;
        font-weight: 400;
        font-size: 12px;
        text-transform: uppercase;
        text-align: center;
      }
    }
    & .Mui-active {
      color: #1858fb;
      & text {
        fill: #fff;
      }
    }
  }
`;

import FileLoad from "./FileLoad";
import InputLabel from "./InputLabel";
import { Title } from "./Page";

export const AboutProject = () => {
  return (
    <>
      <Title>3. Подробнее о проекте</Title>

      <FileLoad
        title={"У вас есть ТЗ или пожелания по проекту?"}
        fieldName={"technicalSpecifications"}
      />
      <InputLabel
        title={
          "Есть ли у вас особая идея? Возможно у вас есть особое видение будущего сайта?"
        }
        placeholder={"Pushkeen"}
        size={"50%"}
        name={"idea"}
        required={false}
      />
      <InputLabel
        title={
          "Укажите предполагаемые основные разделы вашего будущего сайта (структуру)"
        }
        placeholder={"Pushkeen"}
        size={"50%"}
        name={"sections"}
        required={false}
      />
      <InputLabel
        title={"Есть ли у вас прототип будущего сайта?"}
        placeholder={"Pushkeen"}
        size={"50%"}
        name={"sitePrototype"}
        required={false}
      />
    </>
  );
};

import { useState, useEffect } from "react";
import s from "./Contact.module.scss";
import { Select, MenuItem, SelectChangeEvent } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import "./DropDown.scss";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import { useTranslation } from "react-i18next";
import cn from "classnames";

const services = [
  "Web-разработка",
  "Мобильное приложение",
  "Настольная игра / Комикс",
  "Мобильная игра / AR",
  "2D и 3D графика",
];

const DropDown = ({ selectedService, setService }: any) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState<boolean>(false);

  const handleChange = (event: SelectChangeEvent<typeof selectedService>) => {
    const {
      target: { value },
    } = event;
    setService(typeof value === "string" ? value.split(",") : value);
  };

  useEffect(() => {
    window.addEventListener("scroll", () => setOpen(false));
  }, []);

  return (
    <FormControl className={cn(s.dropDown, "dropDown_select")} required>
      <Select
        multiple
        displayEmpty
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        id="demo-simple-select"
        value={selectedService}
        name="service"
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        }}
        renderValue={(selected) => {
          if (selected.length === 0) {
            return <p>{t("main.contact.placeholders.services.label")}</p>;
          } else {
            return <em>{selected.join(", ")}</em>;
          }
        }}
        onChange={handleChange}
        className={s.dropDown_select}
      >
        {services.map((service) => (
          <MenuItem
            id={"menuItem"}
            key={service}
            value={service}
            sx={
              selectedService.includes(service)
                ? { backgroundColor: "rgba(24, 24, 24, 0.10) !important" }
                : { backgroundColor: "#1852E4" }
            }
          >
            <Checkbox
              icon={
                <DoneOutlinedIcon
                  sx={{ color: "#1852E4", opacity: 0, transition: "1s" }}
                />
              }
              checkedIcon={
                <DoneOutlinedIcon
                  sx={{ color: "white", opacity: 1, transition: "1s" }}
                />
              }
              checked={selectedService.indexOf(service) > -1}
            />
            <em>{service}</em>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default DropDown;

import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {breakpoints} from "../../styles/variables";

const CookieNotification = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const isCookieAccepted = localStorage.getItem('isCookieAccepted');
    if (isCookieAccepted) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  }, []);

  const handleClose = () => {
    localStorage.setItem('isCookieAccepted', 'true');
    setIsOpen(false);
    //console.log(isOpen);
  };

  return (
    <CookieWr open={isOpen}>
      <CookieContainer>
        <Text>
          <Title>Мы используем файлы COOKIE</Title>
          <p>
            Мы используем файлы cookies. Продолжая пользоваться сайтом, вы соглашаетесь с этим. Вы можете
            ознакомиться с детальной информацией о файлах cookies и обработке
            данных в <a href={'https://pushkeen.ru/privacy'} target={'_blank'}>политике конфиденциальности</a>
          </p>
        </Text>
        <Accept onClick={handleClose}>
          Принять
        </Accept>
      </CookieContainer>

    </CookieWr>
  );
};

const CookieWr = styled.div`
  position: fixed;
  bottom: ${(props)=> props.open ? '0%' : '-20%'};
  width: 100%;
  z-index: 101;
  transition: 0.6s cubic-bezier(0.36, 0, 0.66, -0.56);
  @media ${breakpoints.laptop} {
    bottom: ${(props)=> props.open ? '0%' : '-100%'};
  }
  @media ${breakpoints.mobile} {
    bottom: ${(props)=> props.open ? '0%' : '-1000%'};
  }
`
const CookieContainer = styled.div`
  max-width: 1322px;
  height: 100%;
  margin: auto;
  margin-bottom: 20px;
  padding: 20px 30px;
  border-radius: 16px;
  border: 1px solid #181818;
  
  display: flex;
  
  background-color: white;
  @media ${breakpoints.laptop} {
    max-width: 768px;
  }
  @media ${breakpoints.mobile} {
    max-width: 346px;
    flex-direction: column;
  }
`
const Text = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  p {
    font-family: Mulish;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;

    //styleName: Main text(desk);
    a {
      font-family: Nunito;
      font-size: 18px;
      font-weight: 800;
      line-height: 27px;
      letter-spacing: 0em;
      transition: 0.2s;
      &:hover {
        color: #0f4cea;
      }
    }
  }
`
const Title = styled.h6`
  color: #0f4cea;
  font-family: IBM Plex Mono;
  font-size: 22px;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: 0em;
  text-transform: uppercase;
`
const Accept = styled.button`
  width: 100px;
  height: 100px;  
  padding: 41px 17px 41px 17px;
  border-radius: 1000px;
  gap: 10px;
  
  transition: 0.2s;
  
  background-color: #181818;
  color: white;
  font-family: 'Open Sans';
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  &:hover {
    background-color: #0f4cea;
  }
  &:focus {
    opacity: 1;
  }
  &:active {
    opacity: 0.8;
  }
  @media ${breakpoints.mobile} {
    width: 100%;
    margin-top: 8px;
    height: fit-content;
    padding: 10px 17px ;
    border-radius: 15px;
    text-align: center;
  }
`

export default CookieNotification;
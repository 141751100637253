import { useTranslation } from "react-i18next";
import s from "./Contact.module.scss";

export const SubmitMessage = () => {
  const { t } = useTranslation();

  return (
    <div className={s.submit}>
      <div className={s.left}>
        <h3 className={s.left_title}>{t("main.contact.submit.title")}</h3>
        <span className={s.left_label}>{t("main.contact.submit.text")}</span>
      </div>
      <div className={s.right}>
        <span className={s.right_title}>
          {t("main.contact.submit.process.title")}
        </span>
        <ul className={s.right_list}>
          <li>
            <div className={s.numbering}>1</div>
            <p>{t("main.contact.submit.process.first")}</p>
          </li>
          <li>
            <div className={s.numbering}>2</div>
            <p>{t("main.contact.submit.process.second")}</p>
          </li>
          <li>
            <div className={s.numbering}>3</div>
            <p>{t("main.contact.submit.process.third")}</p>
          </li>
          <li>
            <div className={s.numbering}>4</div>
            <p>{t("main.contact.submit.process.fourth")}</p>
          </li>
        </ul>
      </div>

      {/*<button*/}
      {/*  className={s.submit_button}*/}
      {/*  onClick={() => {*/}
      {/*    setSubmitMessage(false);*/}
      {/*  }}*/}
      {/*></button> */}
    </div>
  );
};

import {useTranslation} from "react-i18next";
import {Mainscreen} from "shared/components/Mainscreen/Mainscreen";
import s from "./DirtyDrops.module.scss";
import {useEffect} from "react";
import Aos from "aos";
import {useMediaQuery} from "@mui/material";
import {breakpoints} from "styles/variables";
import Video from "../../../shared/components/Video/Video";
import { Helmet } from 'react-helmet';

export const DirtyDrops = () => {
  const isMobile = useMediaQuery(breakpoints.mobile);
  const {t} = useTranslation();

  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <>
      <Helmet
        title="PushKeen – DIRTY DROPS"
        meta={[
          {
            name: "description",
            content: "NFT-коллекция 3D грузовиков",
          },
        ]}
      />
      <section className={s.hydrocosmos}>
        <Mainscreen
          path={`projects/dirtyDrops/mainscreen${
            isMobile ? "-mobile" : ""
          }.jpg`}
        />
        <div className="container">
          <div className={s.about}>
            <div className={s.about_left}>
              <div className={s.about_left_title} data-aos="fade-right">
                <h2 className={s.about_left_title_name}>Dirty drops</h2>
                <span className={s.about_left_title_label}>
                  NFT-коллекция 3D грузовиков
                </span>
              </div>
              <div className={s.buttons}>
                <div className={s.game}>3d графика</div>
              </div>
            </div>

            <div className={s.about_content} data-aos="fade-left">
              <h3 className={s.about_content_name}>О проекте:</h3>
              <span className={s.about_content_label}>
                3D-модельки грузовиков, которые парят в цифровом космосе
                на осколках Москвы, были созданы для NFT-коллекции Dirty Drops.
                На пыльном кузове автомобилей с помощью анимации появляются
                рисунки известного уличного художника ProBoyNick.
                <br />
                <br />
                Мы решили создать коллекцию, чтобы сохранить недолговечные
                арт-объекты. Благодаря цифровым технологиям и точной
                3D визуализации уличные работы навсегда останутся в виртуальном
                мире, где все желающие смогут не только посмотреть на них,
                но и приобрести.
              </span>
            </div>
          </div>
          <div className={s.image_1} data-aos="zoom-in-up">
            <Video
              videoSrc={"projects/dirtyDrops/video.mp4"}
              poster={"projects/dirtyDrops/image1.png"}
            />
          </div>

          <div className={s.task}>
            <div className={s.task_wrapper} data-aos="fade-right">
              <h3 className={s.point_title}>Мы сделали:</h3>
              <ul className={s.point_text}>
                <li>
                  Создали виртуальные арт-объекты для продажи творчества
                  известного автора, используя технологии 3D визуализации и
                  анимации
                </li>
              </ul>
            </div>
            <div className={s.task_date} data-aos="fade-left">
              <span className={s.number}>2</span>
              <span className={s.month}>
                {t("project.list.kdc.steps.date.month")}
              </span>
              <span className={s.label}>
                {t("project.list.kdc.steps.date.label")}
              </span>
            </div>
          </div>
          <div className={s.image_2} data-aos="zoom-in-up">
            <img
              src={require("assets/projects/dirtyDrops/image2.jpg")}
              alt="hydrocosmos"
            />
          </div>
        </div>
      </section>
    </>
  );
};

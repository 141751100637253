export const sizes = {
  desktopWidth: "1400px",
  laptopWidth: "770px",
  // mobileWidth: "450px",
};

export const breakpoints = {
  laptop: `(max-width: ${sizes.desktopWidth})`,
  // tablet: `(max-width: ${sizes.laptopWidth})`,
  mobile: `(max-width: ${sizes.laptopWidth})`,
};
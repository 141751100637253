import s from "pages/Home/Home.module.scss";
import Model from "assets/animate/glb/Test_2";
import MediaQuery from "react-responsive";

import { Canvas } from "@react-three/fiber";
import { Suspense } from "react";

export const Rings = () => {
  return (
    <div className={s.rings_wrapper}>
      <MediaQuery minWidth={1400}>
        <CanvasContext
          options={{
            zoom: 2.9,
            filmOffset: -1.8,
            position: [-0.4, -0.4, 1],
          }}
        />
      </MediaQuery>
      <MediaQuery minWidth={770} maxWidth={1401}>
        <CanvasContext
          options={{
            zoom: 1.5,
            filmOffset: -3,
            position: [-0.4, -0.4, 1],
          }}
        />
      </MediaQuery>
      <MediaQuery maxWidth={770}>
        <CanvasContext
          options={{
            zoom: 1.2,
            filmOffset: 2,
            position: [-0.4, -0.4, 1],
          }}
        />
      </MediaQuery>
    </div>
  );
};

const CanvasContext = ({ options }) => (
  <Canvas className={s.rings} camera={options}>
    <Suspense fallback={null}>
      <ambientLight />
      <directionalLight intensity={2} position={[-5, 15, 3]} />
      <Model />
    </Suspense>
  </Canvas>
);

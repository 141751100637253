import styled from "styled-components";
import { breakpoints } from "styles/variables";

const InputLabel = ({ title, size, placeholder, type, name, required }) => {
  return (
    <InputWrapper size={size ? size : "100%"}>
      <p>{title}</p>
      {type === "area" ? (
        <Area name={name} required={required}></Area>
      ) : (
        <Input
          placeholder={placeholder ? placeholder : ""}
          type={type ? type : "text"}
          name={name}
          required={required}
        />
      )}
    </InputWrapper>
  );
};
export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: ${(props) => props.size};
  margin-bottom: 40px;
  p {
    font-family: "Mulish", sans-serif;
    font-weight: 500;
    color: black;
    font-size: 24px;
    @media ${breakpoints.mobile} {
      font-size: 15px;
    }
  }
  @media ${breakpoints.laptop} {
    width: 100%;
  }
`;
const Input = styled.input`
  border-radius: 8px;
  border: 1px solid #cfd0d7;

  padding: 10px;

  width: 100%;
  height: 45px;

  font-family: "Mulish", sans-serif;
  font-weight: 400;
  color: black;
  //opacity: 0.5;
  font-size: 18px;
  @media ${breakpoints.mobile} {
    font-size: 15px;
  }
`;
const Area = styled.textarea`
  resize: none;
  border-radius: 8px;
  border: 1px solid #cfd0d7;

  padding: 10px;

  width: 100%;
  height: 45px;

  font-family: "Mulish", sans-serif;
  font-weight: 400;
  color: black;
  //opacity: 0.5;
  font-size: 18px;
  padding: 11px 12px;
  width: 988px;
  height: 253px;
  @media ${breakpoints.laptop} {
    width: 100%;
  }
  @media ${breakpoints.mobile} {
    height: 100px;
    font-size: 15px;
  }
`;

export default InputLabel;

import { useState } from "react";
import styled from "styled-components";
import Page from "./Page";
import InputLabel from "./InputLabel";
import Wrapper from "../../../shared/components/Wrapper";
import { nodemailerUrlBrief } from "../../../shared/constants/urls";
import axios from "axios";
import SvgSelector from "shared/components/SvgSelector";
import { HorizontalStepper } from "./Stepper";
import { breakpoints } from "styles/variables";
import { useMediaQuery } from "@mui/material";

const WebDevelopment = () => {
  const [currentPage, setCurrentPage] = useState(0);

  const sendEmail = async (e) => {
    e.preventDefault();

    let formData = new FormData();
    const features =
      (e.target.features0?.checked ? e.target.features0.value + ", " : "") +
      (e.target.features1?.checked ? e.target.features1.value + ", " : "") +
      (e.target.features2?.checked ? e.target.features2.value + ", " : "") +
      (e.target.features3?.checked ? e.target.features3.value + ", " : "");

    formData.append("name", e.target.name?.value);
    formData.append("phone", e.target.phone?.value);
    formData.append("email", e.target.email?.value);
    formData.append("projectName", e.target.projectName?.value);
    formData.append("companyName", e.target.companyName?.value);
    formData.append("fieldOfActivity", e.target.fieldOfActivity?.value);
    formData.append("competitors", e.target.competitors?.value);
    formData.append("services", e.target.services?.value);
    formData.append("audience", e.target.audience?.value);
    formData.append("advantages", e.target.advantages?.value);
    formData.append("clientProblems", e.target.clientProblems?.value);
    formData.append("comparison", e.target.comparison?.value);
    formData.append("qualityPhotos", e.target.qualityPhotos?.value);
    formData.append(
      "technicalSpecifications",
      e.target.technicalSpecifications?.files[0]
    );
    formData.append("idea", e.target.idea?.value);
    formData.append("sections", e.target.sections?.value);
    formData.append("sitePrototype", e.target.sitePrototype?.value);
    formData.append("logo", e.target.logo?.files[0]);
    formData.append("brandBook", e.target.brandBook?.files[0]);
    formData.append("siteLove", e.target.siteLove?.value);
    formData.append("siteHate", e.target.siteHate?.value);
    formData.append("siteFunction", e.target.siteFunction?.value);
    formData.append("sitePages", e.target.sitePages?.value);
    formData.append("features", features);
    formData.append("additionally", e.target.additionally?.value);

    const response = await axios.post(nodemailerUrlBrief, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    console.log("response: ", response);
    if (response.status === 200) {
      e.target.reset();
      setCurrentPage(0);
    }
  };

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const previousPage = () => {
    setCurrentPage(currentPage - 1);
  };
  const isMobile = useMediaQuery(breakpoints.mobile);

  return (
    <Wrapper>
      <Title>Бриф на разработку сайта</Title>
      <form onSubmit={sendEmail} id="breifFormWeb">
        <InputLabel
          title={"Имя*"}
          placeholder={"Имя"}
          name={"name"}
          required={true}
        />
        <Connection>
          <InputLabel
            title={"Ваш мобильный*"}
            placeholder={"+7 (  ) - -"}
            type={"phone"}
            name={"phone"}
            required={true}
          />
          <InputLabel
            title={"Email*"}
            placeholder={"Email"}
            type={"email"}
            name={"email"}
            required={true}
          />
        </Connection>

        <InputLabel
          title={"Название проекта"}
          placeholder={""}
          name={"projectName"}
          required={false}
        />
        {isMobile || <HorizontalStepper activeStep={currentPage} />}

        <Page currentPage={currentPage} />
      </form>

      <ButtontsWr>
        <ButtonBlue onClick={previousPage} disabled={currentPage === 0}>
          <h6>
            <SvgSelector svg="arrow-left" />
          </h6>
        </ButtonBlue>
        {currentPage === 4 ? (
          <>
            <ButtonBlue type={"submit"} form={"breifFormWeb"}>
              <h5>отправить</h5>
            </ButtonBlue>
          </>
        ) : (
          <ButtonBlue onClick={nextPage}>
            <h5>далее</h5>
          </ButtonBlue>
        )}
      </ButtontsWr>
    </Wrapper>
  );
};

const Title = styled.h1`
  margin-top: 48px;
  margin-bottom: 64px;

  font-family: "IBM Plex Mono", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  color: black;
  font-size: 68px;

  @media ${breakpoints.mobile} {
    font-size: 38px;
  }
`;
const Connection = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
`;
const ButtonBlue = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1858fb;
  border-radius: 100px;
  color: white;

  h5 {
    font-family: "IBM Plex Mono", sans-serif;
    line-height: 52px;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 20px;
    margin: 0 100px;
    color: white;
    @media ${breakpoints.mobile} {
      font-size: 15px;
      margin: 0 4vw;
    }
  }

  h6 {
    font-family: "Inter", sans-serif;
    font-size: 35px;
    margin: 0 100px;
    @media ${breakpoints.mobile} {
      margin: 0 4vw;
      font-size: 15px;
    }
  }

  &:disabled {
    background-color: #9b9fb1;
    pointer-events: none;
  }

  &:focus {
    opacity: 1;
  }

  &:active {
    opacity: 0.8;
  }
`;
const ButtontsWr = styled.div`
  display: flex;
  justify-content: space-between;

  width: 100%;
  margin-bottom: 60px;
`;

export default WebDevelopment;

import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import s from "./servicesPage.module.scss";
import { breakpoints } from "styles/variables";
import { Box, Tab, Tabs, useMediaQuery } from "@mui/material";
import Wrapper from "shared/components/Wrapper";
import { useLocation, useNavigate } from "react-router";
import cn from "classnames";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { HashLink } from "react-router-hash-link";
import SvgSelector from "shared/components/SvgSelector";
import { ArrowUp } from "shared/components/ArrowUp/ArrowUp";
import Aos from "aos";

const tabs = [
  { id: 0, label: "Web-разработка", hash: "#web-development" },
  { id: 1, label: "настольные игры", hash: "#board-games" },
  { id: 2, label: "игры и AR", hash: "#games-and-ar" },
  { id: 3, label: "мобильные приложения", hash: "#mobile-apps" },
  { id: 4, label: "2D и 3D графика", hash: "#graphics" },
];

const cards = {
  web: [
    {
      url: "web-tab/sites.jpg",
      title: "Сайты",
      text: "Создаем сайты с&nbsp;современным UX/UI-дизайном, удобным интерфейсом и&nbsp;навигацией. Проектируем архитектуру, разрабатываем фронтенд и&nbsp;бэкенд, проводим тестирование.",
      price: "от 300 000 ₽",
    },
    {
      url: "web-tab/shops.jpg",
      title: "Интернет магазины",
      text: "Запустите свой бизнес с&nbsp;мощным e-commerce решением. Анализируем рынок, создаем визуальный стиль, разрабатываем сайт и&nbsp;настраиваем все системы, от&nbsp;управления заказами до&nbsp;настройки&nbsp;SEO.",
      price: "от 500 000 ₽",
    },
    {
      url: "web-tab/audit.jpg",
      title: "технический аудит",
      text: "Соберем максимум информации, проанализируем технические аспекты бизнеса или&nbsp;другого вашего продукта для&nbsp;выявления проблем и&nbsp;недостатков и&nbsp;предложим потенциальные улучшения.",
      price: "от 50 000 ₽",
    },
  ],
  game: [
    {
      url: "game-tab/brodilky.jpg",
      title: "Бродилки",
      text: "Это&nbsp;интерактивные игры по&nbsp;мотивам классических «ходилок» с&nbsp;красочными игровыми полями, на&nbsp;которых представлены основные достопримечательности города или&nbsp;района.",
      price: "от 3 000 000 ₽",
    },
    {
      url: "game-tab/hranitely.jpg",
      title: "Нить времён",
      text: "Цель игры — расположить в верном хронологическом порядке карточки, изображающие события, объединённые общей темой. Победителем становится игрок, который первым правильно выложит на игровое поле все свои карты.",
      price: "от 1 000 000 ₽",
    },
    {
      url: "game-tab/memo.jpg",
      title: "мемо",
      text: "Классические игры с&nbsp;простыми правилами. В&nbsp;«Мемо» игроку необходимо собрать как&nbsp;можно больше пар одинаковых карточек среди множества других. Кто соберет больше всех пар, тот&nbsp;и&nbsp;победил.",
      price: "от 800 000 ₽",
    },
    {
      url: "game-tab/comix.jpg",
      title: "комиксы",
      text: "Разработаем концепцию, сценарий, графику и&nbsp;все&nbsp;необходимые материалы.",
      price: "от 1 500 000 ₽",
    },
    {
      url: "game-tab/individual.jpg",
      title: "индивидуальная разработка игр",
      text: "Поможем создать уникальную настольную игру любого жанра: от&nbsp;разработки механики до&nbsp;печати тиража игры.",
      price: "от 500 000 ₽",
    },
  ],
  ar: [
    {
      url: "ar-tab/ar.jpg",
      title: "AR",
      text: "AR-технология позволяет добавить цифровые объекты в&nbsp;реальный мир. Создадим новое приложение с&nbsp;AR-контентом, либо&nbsp;добавим AR-функционал в&nbsp;уже&nbsp;существующее.",
      price: "от 200 000 ₽",
    },
    {
      url: "ar-tab/unity.jpg",
      title: "Мобильные игры на&nbsp;unity",
      text: "Предлагаем полный цикл разработки игр разных жанров для&nbsp;Android и&nbsp;IOS: от&nbsp;создания концепции до&nbsp;размещения на&nbsp;торговых площадках.",
      price: "от 1 000 000 ₽",
    },
    // {
    //   url: "ar-tab/graph.jpg",
    //   title: "2d, 3d графика",
    //   text: "Создадим графику, анимацию и&nbsp;AR для&nbsp;различных проектов, включая мобильные игры и&nbsp;мультимедийные системы.",
    //   price: "Индивидуальная стоимость",
    // },
    {
      url: "ar-tab/webar.jpg",
      title: "web-ar",
      text: "Создадим иммерсионную дополненную реальность при&nbsp;помощи WebAR. Такая технология позволит пользователям открыть для&nbsp;себя технологию AR&nbsp;самым простым способом — через&nbsp;Интернет — без&nbsp;необходимости установки какого-либо приложения.",
      price: "Индивидуальная стоимость",
    },
  ],
  app: [
    {
      url: "app-tab/apps.jpg",
      title: "кроссплатформенные мобильные приложения",
      text: "Проектирование и&nbsp;разработка мобильных приложений для&nbsp;бизнеса. Проработка UX/UI, разработка серверной стороны (backend), интеграция с&nbsp;IT-системами, публикация, развитие и&nbsp;поддержка.",
      price: "от 800 000 ₽",
    },
  ],
  graph: [
    {
      url: "graph-tab/graph_1.jpg",
      title: "2D графика",
      text: "Разработаем привлекательные векторные иллюстрации, анимации, а также графику для игр и приложений.",
      price: "Индивидуальная стоимость",
    },
    {
      url: "graph-tab/graph_2.jpg",
      title: "3D графика",
      text: "Поможем с созданием 3D моделей и анимаций, \n которые станут эффективным инструментом вашего бизнеса. Используются везде: от рекламы до настольных игр.",
      price: "Индивидуальная стоимость",
    },
  ],
};

export const ServicesPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    Aos.init();
  }, []);

  return (
    <>
      <Helmet
        title="PushKeen - Наши услуги"
        meta={[
          {
            name: "description",
            content: "Мы создаём креативные проекты в сфере культуры и туризма",
          },
        ]}
      />

      <section className={s.wr}>
        <Wrapper>
          <HorizontalTabs />
          <ArrowUp />
        </Wrapper>
      </section>
    </>
  );
};

const HorizontalTabs = () => {
  const isMobile = useMediaQuery(breakpoints.mobile);

  const navigate = useNavigate();
  const { hash } = useLocation();

  const [value, setValue] = useState(
    tabs.find((tab) => tab.hash === hash)?.id || 0
  );

  const handleChange = (e, newValue) => {
    navigate(e.target.attributes.hash.value);
    setValue(newValue);
  };

  return (
    <Box className={s.tab_box}>
      <Tabs
        selectionFollowsFocus
        variant={isMobile ? "scrollable" : "standard"}
        className={cn(s.tabs, "tabs")}
        orientation={isMobile ? "vertical" : "horizontal"}
        value={value}
        onChange={handleChange}
      >
        {tabs.map((tab) => (
          <Tab
            key={tab.id}
            className={s.tab}
            label={tab.label}
            hash={tab.hash}
            id={tab.id}
            {...a11yProps(tab.id - 1)}
          />
        ))}
      </Tabs>
      {[
        { type: "web", list: cards.web },
        { type: "game", list: cards.game },
        { type: "ar", list: cards.ar },
        { type: "app", list: cards.app },
        { type: "graph", list: cards.graph },
      ].map((tab, i) => (
        <TabPanel value={value} key={i} index={i}>
          <TabWrapper cln={"tab_content__" + tab.type} list={tab.list} />
        </TabPanel>
      ))}
    </Box>
  );
};

const TabPanel = ({ children, value, index }) => (
  <div
    className={s.tab_panel}
    role="tabpanel"
    hidden={value !== index}
    id={`horizontal-tabpanel-${index}`}
    aria-labelledby={`horizontal-tab-${index}`}
  >
    {value === index && <>{children}</>}
  </div>
);

const TabWrapper = ({ cln, list }) => (
  <ul className={cn(s[cln], s.tab_content)}>
    {list.map((card, i) => (
      <TabCard key={i} card={card} />
    ))}
  </ul>
);

const TabCard = ({ card }) => (
  <li className={s.tab_card}>
    <div data-aos="fade-up" data-aos-offset="20">
      <img src={require("assets/services/" + card.url)} alt={card.title} />
      <h2>
        <ReactMarkdown>{card.title}</ReactMarkdown>
      </h2>
      <ReactMarkdown>{card.text}</ReactMarkdown>
      <span>{card.price}</span>
      <HashLink smooth to="/#contact">
        <button>
          <span>Оставить заявку</span>
          <SvgSelector svg={"arrow-submit"} />
        </button>
      </HashLink>
    </div>
  </li>
);

const a11yProps = (index) => ({
  id: `horizontal-tab-${index}`,
  "aria-controls": `horizontal-tabpanel-${index}`,
});

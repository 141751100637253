import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Grid } from "swiper";
import "swiper/css";
import "swiper/css/grid";
import { useMediaQuery } from "@mui/material";
import cn from "classnames";
import s from "./PairsSlider.module.scss";
import home from "pages/Home/Home.module.scss";
import { breakpoints } from "styles/variables";

const pairs = [
  { imageUrl: "rosatom.png", link: "https://www.rosatom.ru/index.html" },
  { imageUrl: "lenfilm.png", link: "https://www.lenfilm.ru" },
  { imageUrl: "museum.png", link: "https://www.lenoblmus.ru" },
  { imageUrl: "bentley.png", link: "https://www.bentleymotors.com/en.html" },
  { imageUrl: "sobaka.png", link: "https://www.sobaka.ru" },
  { imageUrl: "dnevnik.png", link: "https://spbdnevnik.ru" },
  { imageUrl: "gagagames.png", link: "https://www.gaga-games.com" },
  { imageUrl: "pravitelstvo.png", link: "https://www.gov.spb.ru/gov/admin" },
  { imageUrl: "planetariy.png", link: "https://www.planetarium.one" },
  { imageUrl: "rzd.png", link: "https://www.rzd.ru" },
  { imageUrl: "italy_co.png", link: "https://italyco.rest" },
  { imageUrl: "open_city.png", link: "https://открытыйгород.рф" },
  { imageUrl: "rumuseum.png", link: "https://rusmuseum.ru/" },
];

const pairsMobile = [
  { imageUrl: "rosatom.png", link: "https://www.rosatom.ru/index.html" },
  { imageUrl: "bentley.png", link: "https://www.bentleymotors.com/en.html" },
  { imageUrl: "dnevnik.png", link: "https://spbdnevnik.ru" },
  { imageUrl: "lenfilm.png", link: "https://www.lenfilm.ru" },
  { imageUrl: "sobaka.png", link: "https://www.sobaka.ru" },
  { imageUrl: "gagagames.png", link: "https://www.gaga-games.com" },
  { imageUrl: "pravitelstvo.png", link: "https://www.gov.spb.ru/gov/admin" },
  { imageUrl: "museum.png", link: "https://www.lenoblmus.ru" },
  { imageUrl: "planetariy.png", link: "https://www.planetarium.one" },
  { imageUrl: "rzd.png", link: "https://www.rzd.ru" },
  { imageUrl: "rumuseum.png", link: "https://rusmuseum.ru/" },
];

export default function PairsSlider() {
  const isMobile = useMediaQuery(breakpoints.mobile);
  const isLaptop = useMediaQuery(breakpoints.laptop);
  const speed = 800;

  const generateSlides = (pairsArray) => {
    const multiplier = 32; // дубликаты исходного массива для подстановки в очередь
    const combinedPairs = Array(multiplier).fill(pairsArray).flat();
    return combinedPairs.map((pair, i) => (
      <SwiperSlide key={i}>
        <a
          href={pair.link}
          target="_blank"
          rel="nofollow"
          className={home.pairs_grid_card}
        >
          <img src={require("assets/pairs/" + pair.imageUrl)} alt="Pair" />
        </a>
      </SwiperSlide>
    ));
  };

  return (
    <Swiper
      speed={speed}
      slidesPerView={isMobile ? 1 : isLaptop ? 2 : 3}
      slidesPerGroup={1}
      loop={true}
      loopFillGroupWithBlank={true}
      spaceBetween={30}
      modules={[Autoplay, Grid]}
      autoplay={{
        delay: isMobile ? 2500 : 4000,
        disableOnInteraction: false,
      }}
      className={s.pairsSlider}
      grid={{
        rows: isMobile ? 1 : 2,
        fill: "row",
      }}
    >
      {isMobile
        ? Array.from({ length: Math.ceil(pairsMobile.length / 3) }).map(
            (_, index) => (
              <SwiperSlide key={index}>
                <div className={s.slide_wrapper}>
                  {pairsMobile
                    .slice(index * 3, index * 3 + 3)
                    .map((pair, i) => (
                      <a
                        key={i}
                        href={pair.link}
                        target="_blank"
                        rel="nofollow"
                        className={cn(s.slide, s[`slide_${i + 1}`])}
                      >
                        <img
                          src={require("assets/pairs/" + pair.imageUrl)}
                          alt="Pair"
                        />
                      </a>
                    ))}
                </div>
              </SwiperSlide>
            )
          )
        : generateSlides(pairs)}
    </Swiper>
  );
}

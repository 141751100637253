import s from "./services.module.scss";
import { useTranslation } from "react-i18next";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { useMediaQuery } from "@mui/material";
import { breakpoints } from "styles/variables";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import cn from "classnames";
import SvgSelector from "../SvgSelector";
import { Link } from "react-router-dom";

const arUrlList = ["ar_1.jpg", "ar_2.jpg", "ar_3.jpg"];
const webUrlList = ["web_1.jpg", "web_2.jpg", "web_3.jpg", "web_4.jpg"];
const appUrlList = ["app_1.jpg", "app_2.jpg", "app_3.jpg", "app_4.jpg"];
const gameUrlList = ["game_1.jpg", "game_2.jpg", "game_3.jpg"];
const graphUrlList = ["graph_1.jpg", "graph_2.jpg", "graph_3.jpg" ];

const cards = [
  { type: "ar", list: arUrlList, hash: "games-and-ar" },
  { type: "web", list: webUrlList, hash: "web-development" },
  { type: "app", list: appUrlList, hash: "mobile-apps" },
  { type: "game", list: gameUrlList, hash: "board-games" },
  { type: "graph", list: graphUrlList, hash: "graphics" },
];

export default function Services() {
  const { t } = useTranslation();
  const isMobile = useMediaQuery(breakpoints.mobile);
  return (
    <section className={s.wr}>
      {isMobile ? (
        <Swiper
          spaceBetween={5}
          className={cn(s.slider, "swiper-services")}
          loop={true}
          slidesPerView="auto"
        >
          {cards.map((card, i) => (
            <SwiperSlide key={i}>
              <Card type={card.type} urlList={card.list} hash={card.hash}>
                <span>
                  <ReactMarkdown>
                    {t(`main.service.${card.type}`)}
                  </ReactMarkdown>
                </span>
                <ReactMarkdown>
                  {t(`main.service.${card.type}-label`)}
                </ReactMarkdown>
              </Card>
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <>
          {cards.map((card, i) => (
            <Card key={i} type={card.type} urlList={card.list} hash={card.hash}>
              <span>
                <ReactMarkdown>{t(`main.service.${card.type}`)}</ReactMarkdown>
              </span>
              <ReactMarkdown>
                {t(`main.service.${card.type}-label`)}
              </ReactMarkdown>
            </Card>
          ))}
        </>
      )}
    </section>
  );
}

const Card = ({ type, children, urlList, hash }) => (
  <Link to={"/services#" + hash} className={cn(s.card, s[type])}>
    <section>
      <button>
        <SvgSelector svg={"arrow-up-right"} />
      </button>
      {children}
    </section>
    {urlList.map((url, i) => (
      <div key={i} className={s.img_wr}>
        <img src={require("assets/services/" + url)} alt={type} />
      </div>
    ))}
  </Link>
);

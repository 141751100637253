import s from "./Contact.module.scss";
import { useEffect, useState } from "react";
import { SubmitMessage } from "./SubmitMessage";
import axios from "axios";
import DropDown from "./dropDown";
import { Link } from "react-router-dom";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { useTranslation } from "react-i18next";
import { nodemailerUrl } from "shared/constants/urls";

export const Contact = () => {
  const { t } = useTranslation();

  const [submitted, setSubmitted] = useState<boolean>(false);
  const [selectedService, setService] = useState<string[]>([]);
  const [selectedFile, setSelectedFile] = useState<File>();
  
  const fileInputChange = (event: any) => {
    setSelectedFile(event.target.files[0]);
  };
  
  useEffect(() => {
    if (document.getElementById("idDownloadFile")) {
      const DownloadFile = document.getElementById(
        "idDownloadFile"
      ) as HTMLElement;
      DownloadFile.style.opacity = "1";
      DownloadFile.style.height = "100%";
    }
  });

  const sendEmail = async (e: any) => {
    e.preventDefault();
    setSubmitted(true);
    setSelectedFile(undefined);
    setService([]);
    setTimeout(() => {
      setSubmitted(false);
    }, 10000);

    let formData = new FormData();
    formData.append("file", e.target.file.files[0]);
    formData.append("name", e.target.name.value);
    formData.append("phone", e.target.phone.value);
    formData.append("email", e.target.email.value);
    formData.append("text", e.target.text.value);
    formData.append("services", selectedService.join("; "));

    const response = await axios.post(nodemailerUrl, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    console.log(response.data);
  };

  return (
    <section
      className={s.contact}
      id={"contact"}
      style={{ backgroundColor: submitted ? "#24FFCA" : "#1858FB" }}
    >
      <div className="container">
        <div className={s.contact_wrapper}>
          {submitted ? (
            <SubmitMessage />
          ) : (
            <>
              <div className={s.content}>
                <div className={s.title_wrapper}>
                  <span className={s.title}>
                    {t("main.contact.title1")}
                    <span className={s.title}>{t("main.contact.title2")}</span>
                  </span>
                  <p className={s.desc}>{t("main.contact.description")}</p>
                </div>
                <form onSubmit={sendEmail} className={s.form} id="contactForm">
                  <div className={s.input_wrapper}>
                    <div className={s.bracket}>
                      <input
                        type="text"
                        placeholder={t("main.contact.placeholders.name")}
                        pattern="^[A-Za-zА-Яа-яЁё\s\D]+$"
                        minLength={2}
                        maxLength={100}
                        name="name"
                      />
                    </div>
                    <div className={s.bracket}>
                      <input
                        type="tel"
                        placeholder={t("main.contact.placeholders.phone")}
                        pattern="\+?[0-9]+"
                        required
                        name="phone"
                      />
                    </div>
                    <div className={s.bracket}>
                      <input
                        type="text"
                        placeholder={t("main.contact.placeholders.email")}
                        pattern=  '(?=[a-zA-Z0-9]|-|\.*@[a-zA-Z]+\.)(@|[a-zA-Z0-9]|-|_|\.)+'
                        maxLength={50}
                        required
                        name="email"
                      />
                    </div>
                  </div>

                  <DropDown
                    selectedService={selectedService}
                    setService={setService}
                  />

                  <textarea
                    placeholder={t("main.contact.placeholders.form")}
                    minLength={1}
                    maxLength={800}
                    required
                    name="text"
                  ></textarea>

                  <div className={s.attach_file_wrapper}>
                    <label htmlFor={"id_input_btn"}>
                      <span className={s.title}>
                        {t("main.contact.attach_file")}
                      </span>
                    </label>
                    <input
                      type={"file"}
                      id={"id_input_btn"}
                      name="file"
                      className={s.input_btn}
                      onChange={fileInputChange}
                      //accept={".png,.jpg,"}
                      hidden
                    />
                    {selectedFile && (
                      <ul className={s.downloadFile} id={"idDownloadFile"}>
                        <span>Загруженный файл:</span>
                        <li>{selectedFile.name}</li>
                      </ul>
                    )}
                  </div>
                </form>
                {selectedService.toString() === 'Web-разработка' &&
                  <Link to={'/brief/web-development'} className={s.breif}>
                    <h3>Заполнить бриф</h3>
                  </Link>
                }
                <Link className="link" to="/privacy">
                  <span className={s.label_1}>
                    <ReactMarkdown>
                      {t("main.contact.button_label_1")}
                    </ReactMarkdown>
                  </span>{" "}
                  <span className={s.label_2}>
                    {t("main.contact.button_label_2")}
                  </span>
                </Link>
              </div>
              <div className={s.button_wrapper}>
                <button
                  form="contactForm"
                  type="submit"
                  className={s.button}
                  id={"form_btn"}
                >
                  <p className={s.title}>{t("main.contact.button")}</p>
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

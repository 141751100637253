import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { InputWrapper } from "./InputLabel";
import styled from "styled-components";
import { breakpoints } from 'styles/variables';

const labels = [
  "Админ панель",
  "Перенос контента со старого сайта на новый",
  "SEO оптимизация",
  "Интеграция системы метрик",
];

export default function CheckboxLabels() {
  return (
    <InputWrapper size={"100%"}>
      <p>Что из этого необходимо?</p>
      <FormGroup>
        {labels.map((label, index) => (
          <Label
            key={label}
            control={<Checkbox value={label} name={"features" + index} />}
            label={label}
          />
        ))}
      </FormGroup>
    </InputWrapper>
  );
}

const Label = styled(FormControlLabel)`
  & .MuiTypography-root {
    font-family: "Mulish", sans-serif;
    font-weight: 500;
    font-size: 24px;
    color: #181818;
    @media ${breakpoints.mobile} {
      font-size: 15px;
    }
  }
  & .MuiButtonBase-root {
    padding: 0;
  }
  & .MuiSvgIcon-root {
    width: 45px;
    height: 45px;
    margin-right: 16px;
    fill: #cfd0d7;
    @media ${breakpoints.mobile} {
      width: 25px;
      height: 25px;
    }
  }
`;

import CheckboxLabels from "./Checkbox";
import InputLabel from "./InputLabel";
import { Title } from "./Page";

export const Func = () => {
  return (
    <>
      <Title>5. Функциональность сайта</Title>
      <InputLabel
        type={"area"}
        title={
          "Какой сайт можно взять за пример полностью или с частью функционала. Опишите отличия и разницу."
        }
        size={"50%"}
        name={"siteFunction"}
        required={false}
      />
      <InputLabel
        type={"area"}
        title={
          "Опишите какие страницы должны быть? (Главная страница, страницы категорий/разделов, страница «О нас»,…)"
        }
        size={"50%"}
        name={"sitePages"}
        required={false}
      />
      <CheckboxLabels />
      <InputLabel
        title={"Дополнение, замечания, пожелания, требования по сайту"}
        placeholder={"Pushkeen"}
        size={"50%"}
        name={"additionally"}
        required={false}
      />
    </>
  );
};

const SvgSelector = ({ svg }) => {
  switch (svg) {
    case "arrow-left":
      return (
        <svg
          width="27"
          height="24"
          viewBox="0 0 27 24"
          fill="none"
        >
          <path
            d="M12.3547 23.4599L0.758478 11.8636L12.3547 0.267399L14.5919 2.47976L6.82382 10.2479H26.3373V13.4794H6.82382L14.5919 21.2351L12.3547 23.4599Z"
            fill="white"
          />
        </svg>
      );
    case "ar-button":
      return (
        <svg
          width="22"
          height="23"
          viewBox="0 0 22 23"
          fill="none"
        >
          <path
            d="M19.25 14.7576H16.5C16.0138 14.7576 15.5475 14.9507 15.2036 15.2946C14.8598 15.6384 14.6667 16.1047 14.6667 16.5909V19.3409M19.25 19.3409V19.3501M11 6.50758V9.25758C11 9.74381 10.8068 10.2101 10.463 10.5539C10.1192 10.8978 9.6529 11.0909 9.16667 11.0909H6.41667M2.75 11.0909H2.75917M11 2.84091H11.0092M11 14.7576V14.7667M14.6667 11.0909H15.5833M19.25 11.0909V11.1001M11 19.3409V18.4242M3.66667 2.84091H6.41667C6.92293 2.84091 7.33333 3.25132 7.33333 3.75758V6.50758C7.33333 7.01384 6.92293 7.42425 6.41667 7.42425H3.66667C3.16041 7.42425 2.75 7.01384 2.75 6.50758V3.75758C2.75 3.25132 3.16041 2.84091 3.66667 2.84091ZM15.5833 2.84091H18.3333C18.8396 2.84091 19.25 3.25132 19.25 3.75758V6.50758C19.25 7.01384 18.8396 7.42425 18.3333 7.42425H15.5833C15.0771 7.42425 14.6667 7.01384 14.6667 6.50758V3.75758C14.6667 3.25132 15.0771 2.84091 15.5833 2.84091ZM3.66667 14.7576H6.41667C6.92293 14.7576 7.33333 15.168 7.33333 15.6742V18.4242C7.33333 18.9305 6.92293 19.3409 6.41667 19.3409H3.66667C3.16041 19.3409 2.75 18.9305 2.75 18.4242V15.6742C2.75 15.168 3.16041 14.7576 3.66667 14.7576Z"
            stroke="white"
            stroke-width="1.8"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      );
    case "arrow-up-right":
      return (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none">
          <path
            d="M24.003 2.33472L-8.27014e-05 2.33472"
            stroke="white"
            strokeWidth="3.92778"
          />
          <path
            d="M22.0383 2.33044V24.1514"
            stroke="white"
            strokeWidth="3.92778"
          />
          <path
            d="M21.8209 2.33472L2.18253 21.6366"
            stroke="white"
            strokeWidth="3.92778"
          />
        </svg>
      );
    case "arrow-submit":
      return (
        <svg width="17" height="15" viewBox="0 0 17 15" fill="none">
          <path d="M0 9.48986V6.53378H7.31787V9.48986H0Z" fill="#1858FB" />
          <path
            d="M6.87571 12.1149L14.2688 8.09459V7.9054L6.87571 3.88513V1L17 6.58108V9.41892L6.87571 15V12.1149Z"
            fill="#1858FB"
          />
        </svg>
      );
    case "vk":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="53"
          height="54"
          viewBox="0 0 53 54"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M53 27C53 41.6355 41.1355 53.5 26.5 53.5C11.8645 53.5 0 41.6355 0 27C0 12.3645 11.8645 0.5 26.5 0.5C41.1355 0.5 53 12.3645 53 27ZM27.6766 38.7662H25.5888C25.5888 38.7662 20.9823 39.0436 16.9257 34.8202C12.5017 30.2136 8.59458 21.0726 8.59458 21.0726C8.59458 21.0726 8.36935 20.4732 8.61402 20.1835C8.88904 19.8577 9.63823 19.8369 9.63823 19.8369L14.6289 19.8041C14.6289 19.8041 15.0992 19.8829 15.4359 20.1299C15.7133 20.3338 15.8688 20.715 15.8688 20.715C15.8688 20.715 16.6754 22.7549 17.7437 24.6004C19.8286 28.2031 20.7997 28.9912 21.5077 28.6052C22.539 28.0424 22.2298 23.5122 22.2298 23.5122C22.2298 23.5122 22.2488 21.8677 21.7106 21.1356C21.2938 20.568 20.5076 20.4026 20.1605 20.3561C19.8798 20.3186 20.3407 19.6666 20.9382 19.3741C21.8363 18.934 23.4224 18.9089 25.2963 18.9284C26.7562 18.9431 27.1764 19.0341 27.7473 19.1721C29.0718 19.4919 29.0305 20.5167 28.9414 22.7278C28.9147 23.3884 28.8838 24.155 28.8838 25.0404C28.8838 25.2396 28.878 25.4519 28.8721 25.6704C28.8411 26.8028 28.8057 28.0993 29.551 28.581C29.9332 28.8276 30.8682 28.618 33.2069 24.6468C34.3155 22.7649 35.1467 20.5519 35.1467 20.5519C35.1467 20.5519 35.3283 20.1574 35.6104 19.9881C35.8992 19.8155 36.2885 19.8686 36.2885 19.8686L41.5404 19.8359C41.5404 19.8359 43.1184 19.6477 43.374 20.3599C43.6419 21.1076 42.7841 22.8535 40.6385 25.7133C38.6009 28.4291 37.6107 29.4273 37.7038 30.3114C37.7717 30.956 38.4153 31.5399 39.6485 32.6845C42.2336 35.0841 42.9226 36.3441 43.0864 36.6435C43.0996 36.6677 43.1094 36.6857 43.1165 36.6974C44.2735 38.6159 41.8334 38.7662 41.8334 38.7662L37.169 38.8312C37.169 38.8312 36.1666 39.0299 34.8475 38.1237C34.1576 37.6502 33.4832 36.877 32.8406 36.1403C31.8582 35.0141 30.9503 33.9733 30.1755 34.2189C28.8753 34.6315 28.9161 37.4328 28.9161 37.4328C28.9161 37.4328 28.9256 38.0312 28.6292 38.3499C28.3073 38.697 27.6766 38.7662 27.6766 38.7662Z"
            fill="white"
          />
        </svg>
      );
    case "telegram":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="53"
          height="54"
          viewBox="0 0 53 54"
          fill="none"
        >
          <path
            d="M26.5 0.5C11.8648 0.5 0 12.3634 0 27C0 41.6366 11.8648 53.5 26.5 53.5C41.1352 53.5 53 41.6352 53 27C53 12.3648 41.1366 0.5 26.5 0.5ZM38.1863 23.1945C37.4169 27.8718 36.6327 32.5454 35.8338 37.2153C35.7308 37.8255 35.5576 38.4217 35.3176 38.9921C34.8596 40.0535 34.1179 40.4119 33.0108 40.0535C32.3938 39.841 31.8083 39.5464 31.27 39.1775C28.8456 37.5654 26.4391 35.9242 24.0327 34.2858C23.6716 34.0507 23.3326 33.7835 23.0197 33.4873C22.3749 32.8535 22.3195 32.2626 22.9062 31.5707C23.4667 30.9176 24.0908 30.3184 24.7121 29.7206C26.7878 27.7251 28.8912 25.7435 30.9669 23.7508C31.2108 23.5312 31.4286 23.2841 31.616 23.0146C31.7087 22.8679 31.7543 22.5815 31.6672 22.4611C31.58 22.3407 31.3101 22.295 31.1316 22.3144C30.9478 22.3588 30.7765 22.4444 30.6307 22.5649C27.0549 24.9386 23.481 27.3123 19.9089 29.686C19.1575 30.1869 18.3867 30.6657 17.4609 30.7114C16.8432 30.7469 16.2236 30.6885 15.6232 30.5384C14.1605 30.1509 12.7172 29.686 11.2725 29.239C11.0095 29.1572 10.7583 29.0414 10.5253 28.8944C9.89841 28.5014 9.84721 27.9341 10.4187 27.4608C10.7227 27.2075 11.0615 26.9991 11.4248 26.8422C19.0523 23.5986 26.6868 20.3697 34.3282 17.1556C35.1405 16.8244 35.9805 16.566 36.8385 16.3834C38.1448 16.09 38.8464 16.7169 38.8298 18.3622C38.6443 19.771 38.4631 21.4869 38.1863 23.189V23.1945Z"
            fill="white"
          />
        </svg>
      );

    default:
      return <></>;
  }
};

export default SvgSelector;

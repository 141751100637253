import styled from "styled-components";
import InputLabel from "./InputLabel";
import { AboutProject } from "./AboutProject";
import { Design } from "./Design";
import { Func } from "./Func";
import { breakpoints } from 'styles/variables';

const Page = ({ currentPage }) => {
  return (
    <PageWr>
      <Visible show={currentPage === 0}>
        <Title>1. Информация о компании</Title>
        <InputLabel
          title={"Название компании*"}
          placeholder={"Pushkeen"}
          size={"50%"}
          name={"companyName"}
          required={false}
        />
        <InputLabel
          title={"Сфера деятельности*"}
          placeholder={"Разработка сайта"}
          size={"50%"}
          name={"fieldOfActivity"}
          required={false}
        />
        <InputLabel
          title={
            "Вы знаете ваших конкурентов в сети? Если да, дайте на них ссылки:"
          }
          placeholder={"Pushkeen"}
          size={"50%"}
          name={"competitors"}
          required={false}
        />
      </Visible>
      <Visible show={currentPage === 1}>
        <Title>2. Деятельность заказчика</Title>
        <InputLabel
          title={"Продукция, услуги *"}
          placeholder={"Pushkeen"}
          size={"50%"}
          name={"services"}
          required={false}
        />
        <InputLabel
          title={"Кто является целевой аудиторией вашего товара/услуги? *"}
          placeholder={"Возраст, пол, должность,..."}
          size={"50%"}
          name={"audience"}
          required={false}
        />
        <InputLabel
          title={"Выделите ключевое преимущество продукта/услуги:"}
          placeholder={"Pushkeen"}
          size={"50%"}
          name={"advantages"}
          required={false}
        />
        <InputLabel
          title={"С какими проблемами к вам приходит клиент?"}
          placeholder={"Pushkeen"}
          size={"50%"}
          name={"advantages"}
          required={false}
        />
        <InputLabel
          title={
            "На что опирается клиент, сравнивая ваш продукт с продуктом конкурента? Какие критерии являются основными при принятии решении о покупке?"
          }
          placeholder={"Pushkeen"}
          size={"50%"}
          name={"clientProblems"}
          required={false}
        />
        <InputLabel
          title={"У вас есть качественные фото/видео материалы для сайта?"}
          placeholder={"Pushkeen"}
          size={"50%"}
          name={"qualityPhotos"}
          required={false}
        />
      </Visible>
      <Visible show={currentPage === 2}>
        <AboutProject />
      </Visible>
      <Visible show={currentPage === 3}>
        <Design />
      </Visible>
      <Visible show={currentPage === 4}>
        <Func />
      </Visible>
    </PageWr>
  );
};
const PageWr = styled.div`
  width: 100%;
`;
const Visible = styled.div`
  ${({ show }) => `display: ${show ? "block" : "none"}`}
`;
export const Title = styled.h2`
  margin-top: 64px;
  margin-bottom: 48px;

  font-family: "IBM Plex Mono", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  color: black;
  font-size: 44px;
  @media ${breakpoints.mobile} {
    font-size: 25px;
  }
`;
export default Page;

import { useEffect, useRef, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import s from "./headerstyle.module.scss";
import { HashLink, NavHashLink } from "react-router-hash-link";
import { useMediaQuery } from "@mui/material";
import { breakpoints } from "../../styles/variables";
import classNames from "classnames";

const left_links = [
  { type: 0, label: "проекты", link: "/projects" },
  { type: 0, label: "направления работы", link: "/services" },
  { type: 1, label: "контакты", link: "#footer" },
  { type: 1, label: "форма обратной связи", link: "/#contact" },
  { type: 0, label: "PublicArt", link: "/projects/publicart" },
];

const right_links = [
  { label: "веб-разработка", link: "/services", hash: "#web-development" },
  { label: "мобильные приложения", link: "/services", hash: "#mobile-apps" },
  { label: "мобильные игры и AR", link: "/services", hash: "#games-and-ar" },
  {
    label: "настольные игры и комиксы",
    link: "/services",
    hash: "#board-games",
  },
  { label: "2D и 3D графика", link: "/services", hash: "#graphics" },
];
const Header = () => {
  const [headerStatusVisible, setHeaderStatusVisible] = useState(true);
  const [headerStatusHover, setHeaderStatusHover] = useState(false);
  const [worksHover, setWorkHover] = useState(false);
  const headerStatusMobile = useMediaQuery(breakpoints.mobile);

  const currentUrl = window.location.pathname;

  const trigger = useRef(null);

  useEffect(() => {
    let lastScroll = 0;
    const scrollPosition = () => window.pageYOffset;

    document.addEventListener("scroll", (event) => {
      if (lastScroll > scrollPosition()) {
        // scroll up
        setHeaderStatusVisible(true);
      } else if (lastScroll < scrollPosition() && 780 < scrollPosition()) {
        // scroll down
        setHeaderStatusHover(false);
        setHeaderStatusVisible(false);
      } else if (lastScroll < scrollPosition()) {
        setHeaderStatusHover(false);
      }
      lastScroll = scrollPosition();
    });
  }, []);
  if (headerStatusMobile) {
    return (
      //mobile
      <>
        <header
          className={headerStatusHover ? s.hover : ""}
          style={headerStatusVisible ? { top: 0 } : { top: "-60px" }}
        >
          <div className={s.container}>
            <HashLink
              title="продвигаем культуру, внедряем инновации"
              to={"/#PushKeen"}
              className={s.pushKennLink}
            >
              <span>pushkeen</span>
            </HashLink>

            <div className={headerStatusHover ? s.burger_open : s.burger}>
              <button
                type={"button"}
                onClick={() => {
                  setHeaderStatusHover(!headerStatusHover);
                }}
              />
            </div>

            <div className={s.menu}>
              <div className={s.menu_left}>
                {left_links.map((component, index) =>
                  component.type === 0 ? (
                    <Link
                      to={component.link}
                      key={index}
                      className={s.menu_left_link}
                      onMouseOver={() =>
                        setWorkHover(index === 1 ? true : false)
                      }
                      onClick={() => setHeaderStatusHover(false)}
                      style={
                        headerStatusHover
                          ? {
                              opacity: currentUrl === component.link ? 0.5 : 1,
                              left: 0,
                              transitionDelay: `${(5 - index) / 10}s`,
                            }
                          : {
                              opacity: 0,
                              pointerEvents: "none",
                              left: "-10%",
                              transitionDelay: `0s`,
                            }
                      }
                    >
                      {component.label}
                    </Link>
                  ) : (
                    <HashLink
                      to={component.link}
                      key={index}
                      className={s.menu_left_link}
                      onMouseOver={() =>
                        setWorkHover(index === 1 ? true : false)
                      }
                      onClick={() => setHeaderStatusHover(false)}
                      style={
                        headerStatusHover
                          ? {
                              opacity: 1,
                              left: 0,
                              transitionDelay: `${(5 - index) / 10}s`,
                            }
                          : {
                              opacity: 0,
                              pointerEvents: "none",
                              left: "-10%",
                              transitionDelay: `0s`,
                            }
                      }
                    >
                      {component.label}
                    </HashLink>
                  )
                )}
              </div>
            </div>
          </div>
        </header>
        <div
          className={s.blur}
          style={
            headerStatusHover
              ? { height: "100%", opacity: 1 }
              : { height: 0, opacity: 0 }
          }
          onClick={() => setHeaderStatusHover(false)}
        />
      </>
    );
  } else {
    return (
      //desktop
      <>
        <header
          onTransitionEndCapture={() => {
            if (headerStatusHover) {
              trigger.current.style = "pointer-events: none;";
            } else {
              setTimeout(() => {
                trigger.current.style = "pointer-events: auto;";
              }, 500);
            }
          }}
          onMouseLeave={() => {
            setHeaderStatusHover(false);
            setWorkHover(false);
          }}
          className={headerStatusHover ? s.hover : ""}
          style={headerStatusVisible ? { top: 0 } : { top: "-60px" }}
        >
          <div className={s.container}>
            <NavHashLink
              title="продвигаем культуру, внедряем инновации"
              to={"/#PushKeen"}
              className={s.pushKennLink}
              onClick={() => setHeaderStatusHover(false)}
            >
              <span>pushkeen</span>
            </NavHashLink>

            <HashLink
              to={"/#contact"}
              className={s.contactLink}
              onClick={() => setHeaderStatusHover(false)}
            >
              <span>связаться с нами</span>
            </HashLink>

            <div className={s.menu}>
              <div className={s.menu_left}>
                {left_links.map((component, index) =>
                  component.type === 0 ? (
                    <Link
                      to={component.link}
                      key={index}
                      className={classNames(
                        s.menu_left_link,
                        currentUrl === component.link
                          ? s.menu_left_link__disabled
                          : ""
                      )}
                      onMouseOver={() =>
                        setWorkHover(index === 1 ? true : false)
                      }
                      onClick={() => setHeaderStatusHover(false)}
                      style={
                        headerStatusHover
                          ? {
                              opacity: currentUrl === component.link ? 0.5 : 1,
                              left: 0,
                              transitionDelay: `${(5 - index) / 10}s`,
                            }
                          : {
                              opacity: 0,
                              left: "-10%",
                              transitionDelay: `0s`,
                            }
                      }
                    >
                      {component.label}
                    </Link>
                  ) : (
                    <HashLink
                      to={component.link}
                      key={index}
                      className={s.menu_left_link}
                      onMouseOver={() =>
                        setWorkHover(index === 1 ? true : false)
                      }
                      onClick={() => setHeaderStatusHover(false)}
                      style={
                        headerStatusHover
                          ? {
                              opacity: 1,
                              left: 0,
                              transitionDelay: `${(5 - index) / 10}s`,
                            }
                          : {
                              opacity: 0,
                              left: "-10%",
                              transitionDelay: `0s`,
                            }
                      }
                    >
                      {component.label}
                    </HashLink>
                  )
                )}

                <figure
                  className={s.central_line}
                  style={worksHover ? { height: "100%" } : { height: 0 }}
                />
              </div>

              <div className={s.menu_right}>
                {right_links.map((component, index) => (
                  <NavLink
                    to={component.link + component.hash}
                    className={s.menu_right_link}
                    key={index}
                    onClick={() => {
                      setHeaderStatusHover(false);
                      setWorkHover(false);
                    }}
                    style={
                      worksHover
                        ? {
                            opacity: 1,
                            right: 0,
                            pointerEvents: "auto",
                            transitionDelay: `${index / 10}s`,
                          }
                        : {
                            opacity: 0,
                            pointerEvents: "none",
                            right: "10%",
                            transitionDelay: `${index / 10}s`,
                          }
                    }
                  >
                    {component.label}
                  </NavLink>
                ))}
              </div>

              <div
                ref={trigger}
                className={s.trigger}
                onMouseOver={() => {
                  setHeaderStatusHover(true);
                }}
              >
                <figure className={s.bottom_line} />
              </div>
            </div>
          </div>
        </header>
        <div
          className={s.blur}
          style={
            headerStatusHover
              ? { height: "100%", opacity: 1 }
              : { height: 0, opacity: 0 }
          }
        />
      </>
    );
  }
};

export default Header;

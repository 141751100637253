import styled from "styled-components";
import InputLabel from "./InputLabel";
import { Title } from "./Page";
import FileLoad from "./FileLoad";
import { breakpoints } from 'styles/variables';

export const Design = () => {
  return (
    <>
      <Title>4. дизайн</Title>
      <Title_sm>Вид дизайна</Title_sm>

      <FileLoad title={"Логотип"} fieldName={"logo"} />
      <FileLoad
        title={"Дополнительно (к примеру, бренд-бук)"}
        fieldName={"brandBook"}
      />
      <InputLabel
        title={"Сайты, которые нравятся"}
        placeholder={"Pushkeen"}
        size={"50%"}
        name={"siteLove"}
        required={false}
      />
      <InputLabel
        title={"Сайты, которые не нравятся"}
        placeholder={""}
        size={"50%"}
        name={"siteHate"}
        required={false}
      />
    </>
  );
};

const Title_sm = styled.div`
  font-family: "Mulish", sans-serif;
  font-weight: 500;
  color: black;
  font-size: 24px;
  margin-bottom: 32px;
  @media ${breakpoints.mobile} {
    font-size: 15px;
  }
`;

import {ReactMarkdown} from "react-markdown/lib/react-markdown";
import s from "./projectMainBox.module.scss";

type ProjectCardPropsType = {
  topLeftSpan: string;
  description: string;
  backgroundColor: string;
  image: string;
};

const ProjectMainBox = (props: ProjectCardPropsType) => {
  const finalCardClassName = `${s.projectCard} ${
    props.backgroundColor === "firstGrad"
      ? s.firstGrad
      : props.backgroundColor === "secondGrad"
        ? s.secondGrad
        : props.backgroundColor === "thirdGrad"
          ? s.thirdGrad
          : s.fourthGrad
  }`;

  const finalTextColorClassName = `${s.topRightSpan} ${
    props.backgroundColor !== "firstGrad"
      ? s.topRightSpanBlackText
      : s.topRightSpan
  }`;

  const finalDescriptionTextColor = `${s.bottomLeftText} ${
    props.backgroundColor !== "firstGrad" ? s.blackText : s.bottomLeftText
  }`;

  const imgClass =
    props.backgroundColor === "firstGrad"
      ? s.gidroImg
      : props.backgroundColor === "secondGrad"
        ? s.gorodaImg
        : props.backgroundColor === "thirdGrad"
          ? s.ncdImg
          : s.trainImg;

  return (
    <div className={s.projectCardContainer}>
      <div className={finalCardClassName}>
        <div className={s.left}>
          <div className={s.buttonsContainer}>
            <span className={finalTextColorClassName}>{props.topLeftSpan}</span>

          </div>
          <div className={finalDescriptionTextColor}>
            <ReactMarkdown>{props.description}</ReactMarkdown>
          </div>
        </div>
        <img src={props.image} alt={"logo"} className={imgClass}/>
      </div>
    </div>
  );
};

export default ProjectMainBox;

import s from "./Home.module.scss";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import "react-lazy-load-image-component/src/effects/blur.css";
import {useEffect} from "react";
import PairsSlider from "shared/components/PairsSlider/PairsSlider";
import {Helmet} from "react-helmet";
import {motion} from "framer-motion";
import {titleVariants, viewPort} from "shared/constants/framer";
import Video from "shared/components/Video/Video";
import gidroImg from "../../assets/projectsImg/gidro.png";
import gorodaImg from "../../assets/projectsImg/goroda.png"
import ncdImg from  "../../assets/projectsImg/ncd.png"
import trainImg from "../../assets/projectsImg/train.png"
import Services from "shared/components/Services/Services";
import {Rings} from "shared/components/Rings";
import ProjectMainBox from "shared/components/ProjectMainBox/ProjectMainBox";
import {useMediaQuery} from "@mui/material";
import {breakpoints} from "styles/variables";
import {ArrowUp} from "shared/components/ArrowUp/ArrowUp";

export const Home = () => {
  const isMobile = useMediaQuery(breakpoints.mobile);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {t} = useTranslation();

  return (
    <>
      <Helmet>
        <title>PushKeen</title>
      </Helmet>
      <section className={s.main} id={"PushKeen"}>
        <div className={s.title_container}>
          <h1 className={s.title}>pushkeen</h1>
        </div>
        <div className="container">
          <div className={s.wrapper_slogan}>
            <div className={s.slogan}>
              <p className={s.slogan_text}>
                <span className={s.arrow}>Push in culture</span>
                <br/>
                <span className={s.star}>keen on technology</span>
              </p>
              <span className={s.slogan_desc}>
                *продвигаем культуру, внедряем инновации
              </span>
            </div>
            <Rings/>
          </div>
        </div>
      </section>
      <div className="container">
        <Video videoSrc={"Showreel"} typeWebm={true} poster={"showreel.jpg"}/>
        <section className={s.projects}>
          <div className={s.projects_wrapper}>
            <span className={s.projects_title}>{t("main.projects")}</span>
          </div>
          <motion.p
            transition={{duration: 0.5, delay: 0.2}}
            initial="offscreen"
            whileInView="onscreen"
            viewport={viewPort}
            variants={titleVariants}
            className={s.activity_desc}
          >
            {t("main.activity.description")}
          </motion.p>
          <Services/>
        </section>
      </div>
      <section className={s.activity}>
        <div className="container">
          <div className={s.activity_header}>
            <motion.span
              className={s.activity_title}
              transition={{duration: 0.5}}
              initial="offscreen"
              whileInView="onscreen"
              viewport={viewPort}
              variants={titleVariants}
            >
              {t("main.activity.title")}
            </motion.span>
            {isMobile || (
              <Link className={s.button_more} to="/projects">
                {t("main.button_plus")}
              </Link>
            )}
          </div>
          <div className={s.projects_box}>
            <Link to="/projects/hydrocosmos">
              <ProjectMainBox
                topLeftSpan={"многостраничный сайт"}
                description={"сайт для научного журнала"}
                backgroundColor={"firstGrad"}
                image={gidroImg}
              />
            </Link>
            <Link to="/projects/goroda-poyezda">
              <ProjectMainBox
                topLeftSpan={"настольная игра"}
                description={"«Города и поезда»"}
                backgroundColor={"secondGrad"}
                image={gorodaImg}
              />
            </Link>
            <Link to="/projects/GazpromNCA">
              <ProjectMainBox
                topLeftSpan={"2D и 3D графика"}
                description={"Газпром X NCA"}
                backgroundColor={"thirdGrad"}
                image={ncdImg}
              />
            </Link>
            <Link to="/projects/train-detective">
              <ProjectMainBox
                topLeftSpan={"мобильная игра"}
                description={"train detective"}
                backgroundColor={"fourthGrad"}
                image={trainImg}
              />
            </Link>
          </div>
          {isMobile && (
            <Link className={s.button_more} to="/projects">
              {t("main.button_plus")}
            </Link>
          )}
        </div>
      </section>
      <div className="container">
        <section className={s.pairs}>
          <motion.span
            className={s.pairs_title}
            transition={{duration: 0.5}}
            initial="offscreen"
            whileInView="onscreen"
            viewport={viewPort}
            variants={titleVariants}
          >
            {t("main.pairs.title")}
          </motion.span>
          <PairsSlider/>
        </section>
      </div>
      <ArrowUp/>
    </>
  );
};

import { useEffect } from "react";
import s from "./Policy.module.scss";
import { Helmet } from "react-helmet";

export const Policy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>PushKeen - Политика конфиденциальности</title>
      </Helmet>
      <section className={s.policy}>
        <div className="container">
          <h2 className={s.title}>
            Политика в отношении обработки персональных данных
          </h2>
          <p className={s.text}>
            Настоящая политика обработки персональных данных и
            конфиденциальности персональной информации (далее – Политика)
            составлена в соответствии с требованиями Федерального закона от
            27.07.2006. №152-ФЗ «О персональных данных» (далее - Закон о
            персональных данных) и определяет порядок обработки персональных
            данных и меры по обеспечению безопасности персональных данных,
            предпринимаемые ООО «Цифровые Решения» (далее – Оператор) и
            действует в отношении всей информации, которую Оператор и/или ее
            аффилированные лица, включая все лица, входящие в одну группу с
            Оператором, могут получить о пользователе во время использования им
            любого из сайтов, мобильных приложений, сервисов, служб, программ и
            продуктов Оператора (далее — Сервисы), а также в ходе исполнения
            Оператором / его аффилированными лицами любых соглашений и
            договоров, заключенных с пользователем в связи с использованием
            Сервисов. Согласие пользователя на предоставление персональных
            данных (персональной информации), данное им в соответствии с
            настоящей Политикой в рамках отношений с одним из лиц, входящих в
            группу лиц Оператора, распространяется на все лица, входящие в
            данную группу лиц. Использование Сервисов означает безоговорочное
            согласие пользователя с настоящей Политикой и указанными в ней
            условиями обработки его персональной информации, а именно на
            совершение действий, предусмотренных п. 3 ч. 1 ст. 3 Закона о
            персональных данных, как без, так и с использованием средств
            автоматизации, и подтверждает, что, давая такое согласие, он
            действует свободно, своей волей и в своем интересе; в случае
            несогласия с этими условиями пользователь должен воздержаться от
            использования Сервисов.
            <br />
            1. Общие положения
            <br />
            1.1. В рамках настоящей Политики под «персональной информацией
            пользователя» понимаются:
            <br />
            1.1.1. Персональная информация, которую пользователь предоставляет о
            себе самостоятельно при регистрации (создании учетной записи) или в
            процессе использования Сервисов, включая персональные данные
            пользователя. Обязательная для предоставления Сервисов (оказания
            услуг) информация помечена специальным образом. Иная информация
            предоставляется пользователем на его усмотрение.
            <br />
            1.1.2. Данные, которые автоматически передаются Сервисам в процессе
            их использования с помощью установленного на устройстве пользователя
            программного обеспечения, в том числе IP-адрес, информация из
            cookie, информация о браузере пользователя (или иной программе, с
            помощью которой осуществляется доступ к Сервисам), время доступа,
            адрес запрашиваемой страницы.
            <br />
            1.1.3. Иная информация о пользователе в случае, если ее сбор и/или
            предоставление определено в регулирующих документах отдельных
            Сервисов. 1.2. Оператор ставит своей важнейшей целью и условием
            осуществления своей деятельности соблюдение прав и свобод человека и
            гражданина при обработке его персональных данных, в том числе защиты
            прав на неприкосновенность частной жизни, личную и семейную тайну.
            <br />
            1.3. Настоящая Политика применима только к Сервисам Оператора.
            Оператор не контролирует и не несет ответственность за сайты третьих
            лиц, на которые пользователь может перейти по ссылкам, доступным на
            сайтах Оператора. На таких сайтах у пользователя может собираться
            или запрашиваться иная персональная информация, а также могут
            совершаться иные действия.
            <br />
            1.4. Оператор в общем случае не проверяет достоверность персональной
            информации, предоставляемой пользователями, и не осуществляет
            контроль за их дееспособностью. Однако Оператор исходит из того, что
            пользователь предоставляет достоверную и достаточную персональную
            информацию по вопросам, предлагаемым в форме регистрации, что
            указанные им при регистрации персональные данные принадлежат лично
            ему и поддерживает эту информацию в актуальном состоянии. Риск
            предоставления недостоверной информации несет предоставивший ее
            пользователь.
            <br />
            1.5. Согласие пользователя на обработку персональных данных
            действует бессрочно с момента начала использования сайта или
            приложения, и не требует периодического подтверждения. Оператор
            будет хранить персональную информацию столько времени, сколько это
            необходимо для достижения цели, для которой она была собрана, или
            для соблюдения требований законодательства и нормативных актов.
            <br />
            2. Цели сбора и обработки персональной информации пользователей
            <br />
            2.1. Оператор собирает, обрабатывает и хранит только те персональные
            данные, которые необходимы для предоставления Сервисов и оказания
            услуг (исполнения соглашений и договоров с пользователем), в строгом
            соответствии с действующим законодательством. <br />
            2.2. Персональную информацию пользователя Оператор может
            использовать в следующих целях: <br />
            2.2.1. Идентификация стороны в рамках соглашений и договоров с
            Оператором; <br />
            2.2.2. Обработка необходима для выполнения договорных обязательств
            Оператора перед пользователем; <br />
            2.2.3. Связь с пользователем, в том числе направление уведомлений,
            запросов и информации, касающихся использования Сервисов, оказания
            услуг, а также обработка запросов и заявок от пользователя; <br />
            2.2.4. Улучшение качества Сервисов, удобства их использования,
            разработка новых Сервисов и услуг; <br />
            2.2.5. Таргетирование рекламных материалов; <br />
            2.2.6. Проведение статистических и иных исследований на основе
            обезличенных данных. <br />
            3. Основные права и обязанности Оператора <br />
            3.1. Оператор имеет право: – получать от субъекта персональных
            данных достоверные информацию и/или документы, содержащие
            персональные данные; – в случае отзыва субъектом персональных данных
            согласия на обработку персональных данных Оператор вправе продолжить
            обработку персональных данных без согласия субъекта персональных
            данных при наличии оснований, указанных в Законе о персональных
            данных; – самостоятельно определять состав и перечень мер,
            необходимых и достаточных для обеспечения выполнения обязанностей,
            предусмотренных Законом о персональных данных и принятыми в
            соответствии с ним нормативными правовыми актами, если иное не
            предусмотрено Законом о персональных данных или другими федеральными
            законами. 3.2. Оператор обязан: – предоставлять субъекту
            персональных данных по его просьбе информацию, касающуюся обработки
            его персональных данных; – организовывать обработку персональных
            данных в порядке, установленном действующим законодательством РФ; –
            отвечать на обращения и запросы субъектов персональных данных и их
            законных представителей в соответствии с требованиями Закона о
            персональных данных; – сообщать в уполномоченный орган по защите
            прав субъектов персональных данных по запросу этого органа
            необходимую информацию в течение 30 дней с даты получения такого
            запроса; – публиковать или иным образом обеспечивать неограниченный
            доступ к настоящей Политике в отношении обработки персональных
            данных; – принимать правовые, организационные и технические меры для
            защиты персональных данных от неправомерного или случайного доступа
            к ним, уничтожения, изменения, блокирования, копирования,
            предоставления, распространения персональных данных, а также от иных
            неправомерных действий в отношении персональных данных; – прекратить
            передачу (распространение, предоставление, доступ) персональных
            данных, прекратить обработку и уничтожить персональные данные в
            порядке и случаях, предусмотренных Законом о персональных данных; –
            исполнять иные обязанности, предусмотренные Законом о персональных
            данных. <br />
            4. Основные права и обязанности субъектов персональных данных <br />
            4.1. Субъекты персональных данных имеют право: – получать
            информацию, касающуюся обработки его персональных данных, за
            исключением случаев, предусмотренных федеральными законами. Сведения
            предоставляются субъекту персональных данных Оператором в доступной
            форме, и в них не должны содержаться персональные данные,
            относящиеся к другим субъектам персональных данных, за исключением
            случаев, когда имеются законные основания для раскрытия таких
            персональных данных. Перечень информации и порядок ее получения
            установлен Законом о персональных данных; – требовать от оператора
            уточнения его персональных данных, их блокирования или уничтожения в
            случае, если персональные данные являются неполными, устаревшими,
            неточными, незаконно полученными или не являются необходимыми для
            заявленной цели обработки, а также принимать предусмотренные законом
            меры по защите своих прав; – выдвигать условие предварительного
            согласия при обработке персональных данных в целях продвижения на
            рынке товаров, работ и услуг; – на отзыв согласия на обработку
            персональных данных; – обжаловать в уполномоченном органе по защите
            прав субъектов персональных данных или в судебном порядке
            неправомерные действия или бездействие Оператора при обработке его
            персональных данных; – на осуществление иных прав, предусмотренных
            законодательством РФ. <br />
            4.2. Субъекты персональных данных обязаны: – предоставлять Оператору
            достоверные данные о себе; – сообщать Оператору об уточнении
            (обновлении, изменении) своих персональных данных. <br />
            4.3. Лица, передавшие Оператору недостоверные сведения о себе, либо
            сведения о другом субъекте персональных данных без согласия
            последнего, несут ответственность в соответствии с законодательством
            РФ. <br />
            5. Оператор может обрабатывать следующие персональные данные
            Пользователя <br />
            5.1. Геолокационные данные, в целях использования функционала
            Сервисов Оператора, собранные приложениями служб смартфона или иного
            устройства Пользователя (в том числе в фоновом режиме). <br />
            5.2. Сервисами Оператора происходит сбор и обработка обезличенных
            данных о посетителях (в т.ч. файлов «cookie») с помощью сервисов
            интернет-статистики (Яндекс Метрика и Гугл Аналитика и других).{" "}
            <br />
            5.3. Вышеперечисленные данные далее по тексту Политики объединены
            общим понятием Персональные данные. <br />
            5.4. Обработка специальных категорий персональных данных, касающихся
            расовой, национальной принадлежности, политических взглядов,
            религиозных или философских убеждений, интимной жизни, Оператором не
            осуществляется. <br />
            5.5. Обработка персональных данных, разрешенных для распространения,
            из числа специальных категорий персональных данных, указанных в ч. 1
            ст. 10 Закона о персональных данных, допускается, если соблюдаются
            запреты и условия, предусмотренные ст. 10.1 Закона о персональных
            данных.
            <br />
            5.6. Согласие Пользователя на обработку персональных данных,
            разрешенных для распространения, оформляется отдельно от других
            согласий на обработку его персональных данных. При этом соблюдаются
            условия, предусмотренные, в частности, ст. 10.1 Закона о
            персональных данных. Требования к содержанию такого согласия
            устанавливаются уполномоченным органом по защите прав субъектов
            персональных данных.
            <br />
            5.6.1 Согласие на обработку персональных данных, разрешенных для
            распространения, Пользователь предоставляет Оператору
            непосредственно.
            <br />
            5.6.2 Оператор обязан в срок не позднее трех рабочих дней с момента
            получения указанного согласия Пользователя опубликовать информацию
            об условиях обработки, о наличии запретов и условий на обработку
            неограниченным кругом лиц персональных данных, разрешенных для
            распространения. <br />
            5.6.3 Передача (распространение, предоставление, доступ)
            персональных данных, разрешенных субъектом персональных данных для
            распространения, должна быть прекращена в любое время по требованию
            субъекта персональных данных. Данное требование должно включать в
            себя фамилию, имя, отчество (при наличии), контактную информацию
            (номер телефона, адрес электронной почты или почтовый адрес)
            субъекта персональных данных, а также перечень персональных данных,
            обработка которых подлежит прекращению. Указанные в данном
            требовании персональные данные могут обрабатываться только
            Оператором, которому оно направлено. <br />
            5.6.4 Согласие на обработку персональных данных, разрешенных для
            распространения, прекращает свое действие с момента поступления
            Оператору требования, указанного в п. 5.6.3 настоящей Политики в
            отношении обработки персональных данных. <br />
            6. Принципы обработки персональных данных <br />
            6.1. Обработка персональных данных осуществляется на законной и
            справедливой основе. <br />
            6.2. Обработка персональных данных ограничивается достижением
            конкретных, заранее определенных и законных целей. Не допускается
            обработка персональных данных, несовместимая с целями сбора
            персональных данных. <br />
            6.3. Не допускается объединение баз данных, содержащих персональные
            данные, обработка которых осуществляется в целях, несовместимых
            между собой. <br />
            6.4. Обработке подлежат только персональные данные, которые отвечают
            целям их обработки. <br />
            6.5. Содержание и объем обрабатываемых персональных данных
            соответствуют заявленным целям обработки. Не допускается
            избыточность обрабатываемых персональных данных по отношению к
            заявленным целям их обработки. <br />
            6.6. При обработке персональных данных обеспечивается точность
            персональных данных, их достаточность, а в необходимых случаях и
            актуальность по отношению к целям обработки персональных данных.
            Оператор принимает необходимые меры и/или обеспечивает их принятие
            по удалению или уточнению неполных или неточных данных. <br />
            6.7. Хранение персональных данных осуществляется в форме,
            позволяющей определить субъекта персональных данных, не дольше, чем
            этого требуют цели обработки персональных данных, если срок хранения
            персональных данных не установлен федеральным законом, договором,
            стороной которого, выгодоприобретателем или поручителем, по которому
            является субъект персональных данных. Обрабатываемые персональные
            данные уничтожаются либо обезличиваются по достижении целей
            обработки или в случае утраты необходимости в достижении этих целей,
            если иное не предусмотрено федеральным законом. 7. Правовые
            основания обработки персональных данных <br />
            7.1. Правовыми основаниями обработки персональных данных Оператором
            являются: – КоАП РФ Статья 13.11. – уставные документы Оператора; –
            договоры, заключаемые между оператором и субъектом персональных
            данных; – федеральные законы, иные нормативно-правовые акты в сфере
            защиты персональных данных; – согласия Пользователей на обработку их
            персональных данных, на обработку персональных данных, разрешенных
            для распространения. <br />
            7.2. Оператор обрабатывает персональные данные Пользователя только в
            случае их заполнения и/или отправки Пользователем самостоятельно
            через специальные формы, расположенные на сайте https://pushkeen.ru/
            или направленные Оператору посредством электронной почты. Заполняя
            соответствующие формы и/или отправляя свои персональные данные
            Оператору, Пользователь выражает свое согласие с данной Политикой.
            <br />
            7.3. Оператор обрабатывает обезличенные данные о Пользователе в
            случае, если это разрешено в настройках браузера Пользователя
            (включено сохранение файлов «cookie» и использование технологии
            JavaScript). <br />
            7.4. Субъект персональных данных самостоятельно принимает решение о
            предоставлении его персональных данных и дает согласие свободно,
            своей волей и в своем интересе. <br />
            8. Условия обработки персональных данных <br />
            8.1. Обработка персональных данных осуществляется с согласия
            субъекта персональных данных на обработку его персональных данных.{" "}
            <br />
            8.2. Обработка персональных данных необходима для достижения целей,
            предусмотренных международным договором Российской Федерации или
            законом, для осуществления возложенных законодательством Российской
            Федерации на оператора функций, полномочий и обязанностей.
            <br />
            8.3. Обработка персональных данных необходима для осуществления
            правосудия, исполнения судебного акта, акта другого органа или
            должностного лица, подлежащих исполнению в соответствии с
            законодательством Российской Федерации об исполнительном
            производстве.
            <br />
            8.4. Обработка персональных данных необходима для исполнения
            договора, стороной которого либо выгодоприобретателем или
            поручителем по которому является субъект персональных данных, а
            также для заключения договора по инициативе субъекта персональных
            данных или договора, по которому субъект персональных данных будет
            являться выгодоприобретателем или поручителем. <br />
            8.5. Обработка персональных данных необходима для осуществления прав
            и законных интересов оператора или третьих лиц либо для достижения
            общественно значимых целей при условии, что при этом не нарушаются
            права и свободы субъекта персональных данных.
            <br />
            8.6. Осуществляется обработка персональных данных, доступ
            неограниченного круга лиц к которым предоставлен субъектом
            персональных данных либо по его просьбе (далее – общедоступные
            персональные данные).
            <br />
            8.7. Осуществляется обработка персональных данных, подлежащих
            опубликованию или обязательному раскрытию в соответствии с
            федеральным законом. <br />
            9. Порядок сбора, хранения, передачи и других видов обработки
            персональных данных Безопасность персональных данных, которые
            обрабатываются Оператором, обеспечивается путем реализации правовых,
            организационных и технических мер, необходимых для выполнения в
            полном объеме требований действующего законодательства в области
            защиты персональных данных.
            <br />
            9.1. Оператор обеспечивает сохранность персональных данных и
            принимает все возможные меры, исключающие доступ к персональным
            данным неуполномоченных лиц. <br />
            9.2. Персональные данные Пользователя никогда, ни при каких условиях
            не будут переданы третьим лицам, за исключением случаев, связанных с
            исполнением действующего законодательства либо в случае, если
            субъектом персональных данных дано согласие Оператору на передачу
            данных третьему лицу для исполнения обязательств по
            гражданско-правовому договору. <br />
            9.3. В случае выявления неточностей в персональных данных,
            Пользователь может актуализировать их самостоятельно, путем
            направления Оператору уведомление на адрес электронной почты
            Оператора hey@pushkeen.ru с пометкой «Актуализация персональных
            данных». <br />
            9.4. Срок обработки персональных данных определяется достижением
            целей, для которых были собраны персональные данные, если иной срок
            не предусмотрен договором или действующим законодательством.
            Пользователь может в любой момент отозвать свое согласие на
            обработку персональных данных, направив Оператору уведомление
            посредством электронной почты на электронный адрес Оператора
            hey@pushkeen.ru с пометкой «Отзыв согласия на обработку персональных
            данных».
            <br />
            9.5. Вся информация, которая собирается сторонними сервисами, в том
            числе платежными системами, средствами связи и другими поставщиками
            услуг, хранится и обрабатывается указанными лицами (Операторами) в
            соответствии с их Пользовательским соглашением и Политикой
            конфиденциальности. Субъект персональных данных и/или Пользователь
            обязан самостоятельно своевременно ознакомиться с указанными
            документами. Оператор не несет ответственность за действия третьих
            лиц, в том числе указанных в настоящем пункте поставщиков услуг.{" "}
            <br />
            9.6. Установленные субъектом персональных данных запреты на передачу
            (кроме предоставления доступа), а также на обработку или условия
            обработки (кроме получения доступа) персональных данных, разрешенных
            для распространения, не действуют в случаях обработки персональных
            данных в государственных, общественных и иных публичных интересах,
            определенных законодательством РФ. <br />
            9.7. Оператор при обработке персональных данных обеспечивает
            конфиденциальность персональных данных. <br />
            9.8. Оператор осуществляет хранение персональных данных в форме,
            позволяющей определить субъекта персональных данных, не дольше, чем
            этого требуют цели обработки персональных данных, если срок хранения
            персональных данных не установлен федеральным законом, договором,
            стороной которого, выгодоприобретателем или поручителем по которому
            является субъект персональных данных.
            <br />
            9.9. Условием прекращения обработки персональных данных может
            являться достижение целей обработки персональных данных, истечение
            срока действия согласия субъекта персональных данных или отзыв
            согласия субъектом персональных данных, а также выявление
            неправомерной обработки персональных данных.
            <br />
            10. Перечень действий, производимых Оператором с полученными
            персональными данными <br />
            10.1. Оператор осуществляет сбор, запись, систематизацию,
            накопление, хранение, уточнение (обновление, изменение), извлечение,
            использование, передачу (распространение, предоставление, доступ),
            обезличивание, блокирование, удаление и уничтожение персональных
            данных. <br />
            10.2. Оператор осуществляет автоматизированную обработку
            персональных данных с получением и/или передачей полученной
            информации по информационно-телекоммуникационным сетям или без
            таковой.
            <br />
            11. Трансграничная передача персональных данных
            <br />
            11.1. Оператор до начала осуществления трансграничной передачи
            персональных данных обязан убедиться в том, что иностранным
            государством, на территорию которого предполагается осуществлять
            передачу персональных данных, обеспечивается надежная защита прав
            субъектов персональных данных.
            <br />
            11.2. Трансграничная передача персональных данных на территории
            иностранных государств, не отвечающих вышеуказанным требованиям,
            может осуществляться только в случае наличия согласия в письменной
            форме субъекта персональных данных на трансграничную передачу его
            персональных данных и/или исполнения договора, стороной которого
            является субъект персональных данных. <br />
            12. Конфиденциальность персональных данных Оператор и иные лица,
            получившие доступ к персональным данным, обязаны не раскрывать
            третьим лицам и не распространять персональные данные без согласия
            субъекта персональных данных, если иное не предусмотрено федеральным
            законом.
            <br />
            13. Заключительные положения <br />
            13.1. Пользователь может получить любые разъяснения по интересующим
            вопросам, касающимся обработки его персональных данных, обратившись
            к Оператору с помощью электронной почты hey@pushkeen.ru. <br />
            13.2. В данном документе будут отражены любые изменения политики
            обработки персональных данных Оператором. Политика действует
            бессрочно до замены ее новой версией.
            <br />
            13.3. Актуальная версия Политики в свободном доступе расположена в
            сети Интернет по адресу{" "}
            <a href="https://pushkeen.ru/privacy/" rel="nofollow">
              <u>https://pushkeen.ru/privacy/</u>
            </a>
            .
          </p>
        </div>
      </section>
    </>
  );
};
